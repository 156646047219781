import {
  CACHE_CONTROL_KEY,
  EMPLOYEE_NOTIFICATION,
  IS_ON_MOBILE_APP,
  LOCATION_SOURCE,
  REGION,
  REGION_NAME,
  ZIP_CODE,
} from '@/utils/constants/cookies';
import {
  FIVE_YEARS_IN_MINUTES,
  TWELVE_HOURS_IN_MINUTES,
  ONE_HOUR_IN_MINUTES,
} from '@/utils/constants/time';
import { addMinutesToDate } from '@/utils/date/add-minutes';

import { type CookieOptions } from '../types';

interface CookiesDefaultOptions extends Omit<CookieOptions, 'name' | 'value'> {}

interface CookiesMandatoryOptions {
  [key: string]: CookiesDefaultOptions;
}

interface CreateCookieOptions {
  (options: CookieOptions): CookieOptions;
}

const defaultOptions: CookiesDefaultOptions = {
  domain: process.env.NEXT_PUBLIC_SESSION_COOKIE_DOMAIN,
  expires: addMinutesToDate(new Date(), FIVE_YEARS_IN_MINUTES),
  httpOnly: false,
  path: '/',
};

const cookiesMandatoryOptions: CookiesMandatoryOptions = {
  [CACHE_CONTROL_KEY]: {
    expires: addMinutesToDate(new Date(), ONE_HOUR_IN_MINUTES),
    secure: true,
    sameSite: 'strict',
    httpOnly: true,
    domain: process.env.NEXT_PUBLIC_SHARED_COOKIE_DOMAIN,
  },
  [EMPLOYEE_NOTIFICATION]: {
    expires: addMinutesToDate(new Date(), TWELVE_HOURS_IN_MINUTES),
  },
  [REGION]: {
    secure: true,
    sameSite: 'strict',
    domain: process.env.NEXT_PUBLIC_SHARED_COOKIE_DOMAIN,
  },
  [REGION_NAME]: {
    secure: true,
    sameSite: 'strict',
    domain: process.env.NEXT_PUBLIC_SHARED_COOKIE_DOMAIN,
  },
  [LOCATION_SOURCE]: {
    secure: true,
    sameSite: 'strict',
    domain: process.env.NEXT_PUBLIC_SHARED_COOKIE_DOMAIN,
  },
  [IS_ON_MOBILE_APP]: {
    secure: true,
    sameSite: 'strict',
    domain: undefined,
  },
  [ZIP_CODE]: {
    secure: true,
    sameSite: 'strict',
    domain: undefined,
  },
};

export const createOptions: CreateCookieOptions = (options) => ({
  ...defaultOptions,
  ...options,
  ...(cookiesMandatoryOptions[options.name] || {}),
});

export const createRemoveOptions = (name: CreateCookieOptions['name']) => {
  const mandatoryOptions = cookiesMandatoryOptions[name] || {};

  const domain =
    'domain' in mandatoryOptions
      ? mandatoryOptions.domain
      : defaultOptions.domain;

  return {
    name,
    domain,
    value: '',
    expires: 0,
  };
};
